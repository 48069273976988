<template>
  <div class="practice-question-list">
    <div
      v-for="(question, index) in questionList"
      :key="index"
      class="practice-question-item"
      :class=" !isCorrect[question.id]&&hasCommit?'practice-question-item-false':''"
    >
      <div class="question-topic">
        <div
          class="topic-index"
        >
          {{ index + 1 }}
        </div>
        <div class="topic-text" v-html="question.title"></div>
      </div>
      <div class="question-options">
        <div v-if="question.questionType === 2" class="options-multiple">
          <el-checkbox-group v-cloak v-model="checkedValue[question.id]">
            <el-checkbox
              v-for="(item, index) in question.items"
              :label="item.prefix"
              :key="index"
              >
              <span v-html="item.prefix+ '&nbsp;'"></span>
              <span v-html="item.content"></span>
              </el-checkbox
            >
          </el-checkbox-group>
        </div>
        <!-- <div v-else-if="question.questionType === 3" class="options-judge">
          <el-radio
            v-for="(item, index) in question.items"
            :label="item.prefix"
            :key="index"
            v-model="checkedValue[question.id]"
            >{{ item.content }}</el-radio
          >
        </div> -->
        <div v-else class="options-single">
          <el-radio
            v-for="(item, index) in question.items"
            :label="item.prefix"
            :key="index"
            v-model="checkedValue[question.id]"
            >
             <span v-html="question.questionType === 3 ? '' : item.prefix" ></span>
             <span v-html="item.content"></span>
            </el-radio
          >
        </div>
      </div>
      <span v-if="hasCommit" class="question-analyze" v-html="'解析：'+question.analyze"></span>
    </div>
    <div v-if="questionList.length" class="pratice-action">
      <el-button type="primary" @click="handleSubmitClick" round>{{
        hasCommit ? '重做' : '提交'
      }}</el-button>
    </div>
    <Empty v-else description="暂无练习题" />
  </div>
</template>

<script>
import { getPracticeInfo,getPracticeList, submitAnswer } from '@/api/course';
import Empty from '../modules/Empty.vue';
export default {
  name: 'PracticeQuestionList',
  props: {
    unitId: String, //课程单元Id
    hasGetData: Boolean
  },
  components: {
    Empty,
  },
  data() {
    return {
      questionList: [],
      hasCommit: false,
      isCorrect: {},
      checkedValue: {},//答案格式
    };
  },
  created: function () {
    
  },
  watch: {
    hasGetData(pre,next) {
      getPracticeInfo({courseUnitId: this.unitId}).then((res) => {
        if(!res.success){
            this.$message.error(res.message || "获取课程数据失败");
            return;
        }
        const result = res.result || {};
        const examId = result.examId;
        examId&&getPracticeList(examId).then((res) => {
            if(!res.success){
              this.$message.error(res.message || "获取课程数据失败");
              return;
            }
            const { response = {} } = res;
            const { name, questionItems } = response.titleItems[0];
            this.initValueToData(questionItems,true);
        });
      })
    }
  },
  methods: {
    initValueToData(dataList,isCreated){
      let values = {};
      let correctValues = {};
      dataList.forEach((item) => {
          values[item.id] = item.questionType === 2 ? [] : '';
          correctValues[item.id] = true;
      });
      isCreated&&(this.questionList =dataList);
      this.checkedValue = values;
      this.isCorrect = correctValues;
    },
    handleOptionChange(key, value) {
      this.checkedValue = value;
    },
    handleSubmitClick() {
      if (!this.hasCommit) {
        let correctValues = {...this.isCorrect};
        const answer = this.questionList.map((item) => {
          correctValues[item.id] = item.questionType === 2 ?item.correctArray=== this.checkedValue[item.id]:item.correct=== this.checkedValue[item.id];
          let completed = !!this.checkedValue[item.id];
          let content =
            item.questionType === 2 ? '' : this.checkedValue[item.id];
          let contentArray =
            item.questionType === 2 ? this.checkedValue[item.id] : [];
          return {
            completed,
            content,
            contentArray,
            itemOrder: item.itemOrder,
            questionId: item.id,
          };
        });
        let params = {
          answerItems: answer,
          doTime: 233,
          id: 2,
          questionId: '',
        };
        submitAnswer(params).then((res) => {
            // console.log(this.isCorrect,'this.isCorrect');
            this.isCorrect = correctValues;
            this.hasCommit = true;
        });
      }else {
        this.hasCommit = false;
        this.initValueToData(this.questionList,false);
      }
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
</style>