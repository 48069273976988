<template>
  <div class="teaching-guide-card">
    <!-- <div class="card-head">
      <img class="head-icon" src="~@/assets/image/match/match-bg.png" />
      <span class="head-text">段落格式1，1行显示（icon居中对齐）</span>
    </div> -->
    <div class="card-content">
      <div class="content-text">
        <!-- <img class="text-icon" src="~@/assets/image/match/match-bg.png" /> -->
        <span class="text" v-html="data"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeachingGuideCard',
  props: {
    data: String,
  },
};
</script>


<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
</style>